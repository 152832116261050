import React from "react"
import {css} from "@emotion/core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {Layout} from "../components"
import Theme from "../styles/theme"
import {RecoilRoot} from "recoil"

const Contact = (): JSX.Element => {
  return (
    <RecoilRoot>
      <div css={ContactStyles}>
        <Layout>
          <div className="contact">
            <div className="contact-form">
              <h1>
                Everything starts <br /> with a hello
              </h1>
              <div className="separator"></div>
              <h4>Send us a message</h4>
              <form action="">
                <input type="text" name="full_name" placeholder="Your name" />
                <input type="email" name="email" placeholder="Email" />
                <textarea name="message" placeholder="Your message"></textarea>
                <input type="submit" value="Send message" />
              </form>
            </div>
            <div className="contact-details">
              <img src="/office.png" alt="" />

              <div className="details">
                <h4>Reach out to us</h4>
                <div className="phone">
                  <FontAwesomeIcon icon={["fas", "phone-alt"]} />
                  <span>+31 20 7155310</span>
                </div>
                <div className="email">
                  <FontAwesomeIcon icon={["fas", "envelope-open"]} />
                  <span>info@voormedia.com</span>
                </div>
                <div className="address">
                  <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />
                  <span>
                    Meeuwenlaan 100 <br />
                    1021 JL Amsterdam
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </RecoilRoot>
  )
}

export default Contact

const ContactStyles = css`
  overflow-y: scroll;
  max-height: 100vh;
  background: white;

  .header {
    max-width: 144rem;
  }

  #homepage {
    fill: #212121 !important;
  }

  .burger {
    span {
      background-color: #212121 !important;
    }
  }

  main {
    background: white;
    padding: 0;
    overflow: auto;
    padding-top: 7rem;

    @media ${Theme.breakpoints.medium} {
      margin-top: auto;
      padding-top: auto;
    }

    .contact {
      display: flex;
      justify-content: space-between;
      max-width: 144rem;
      margin: 0 auto;
      min-height: calc(100vh - 7rem);
      flex-direction: column;

      @media ${Theme.breakpoints.medium} {
        flex-direction: row;
      }
    }

    h4 {
      font-size: 3.5rem;
      font-weight: 500;
      line-height: 3rem;
    }

    .contact-form {
      width: 100%;
      padding: 0 2.2rem 3rem;

      @media ${Theme.breakpoints.medium} {
        min-width: 40rem;
        padding: 7rem 0 5rem 5.5rem;
        margin-right: 11rem;
        padding: 0 0 0 3rem;
      }

      h1 {
        font-size: 2.7rem;
        line-height: 3.9rem;
        font-weight: 600;
        margin-bottom: 2rem;

        @media ${Theme.breakpoints.medium} {
          font-size: 7rem;
          line-height: 10.6rem;
        }
      }

      .separator {
        margin-bottom: 4rem;

        @media ${Theme.breakpoints.medium} {
          margin-bottom: 4rem;
        }
      }

      h4 {
        font-size: 1.3rem;

        @media ${Theme.breakpoints.medium} {
          font-size: 3.5rem;
        }
      }

      form {
        display: flex;
        flex-direction: column;

        input[type="text"],
        input[type="email"],
        textarea {
          border: 0;
          margin-bottom: 2.1rem;
          padding: 0.9rem 1rem;
          font-size: 1.3rem;
          font-weight: 500;
          line-height: 3.1rem;
          color: ${Theme.colors.fontcolors.body};
          background-color: #f8f7f7;
          height: 3.9rem;

          @media ${Theme.breakpoints.medium} {
            font-size: 2.1rem;
            height: 6.8rem;
            padding: 0.9rem 2rem;
          }

          &:focus {
            outline: none;
          }
        }

        textarea {
          resize: none;
          height: 9.3rem;
          margin-bottom: 1rem;

          @media ${Theme.breakpoints.medium} {
            height: 17.7rem;
            margin-bottom: 3rem;
          }
        }

        ::placeholder {
          color: ${Theme.colors.fontcolors.gray};
        }

        input[type="submit"] {
          margin-top: 1rem;
          cursor: pointer;
          font-size: 1.2rem;
          font-weight: 500;

          @media ${Theme.breakpoints.medium} {
            margin-bottom: 3rem;
            font-size: 1.8rem;
          }

          &:hover {
            font-weight: bold;
          }
        }
      }
    }

    .contact-details {
      background-color: ${Theme.colors.backgrounds.darkGrey};

      @media ${Theme.breakpoints.desktop} {
        max-width: 60rem;
      }

      img {
        display: none;
        width: 100%;

        @media ${Theme.breakpoints.desktop} {
          display: block;
        }
      }

      .details {
        padding: 2.7rem 2.2rem;
        background-color: ${Theme.colors.backgrounds.darkGrey};
        padding: 4rem 3rem;

        @media ${Theme.breakpoints.desktop} {
          padding: 7rem 11rem;
        }
        > div {
          display: flex;
          margin-bottom: 1rem;

          @media ${Theme.breakpoints.medium} {
            margin-bottom: 3rem;
          }

          svg {
            color: white;
            font-size: 2.2rem;
            margin: 0 3.1rem 0 0;
            width: 1.3rem;

            @media ${Theme.breakpoints.medium} {
              width: 2.2rem;
              font-size: 3.5rem;
              margin: 0.5rem 5.2rem 0 0;
            }
          }
        }

        span,
        h4 {
          color: white;
        }

        h4 {
          margin-top: 0;
          font-size: 1.3rem;
          margin-bottom: 2.4rem;

          @media ${Theme.breakpoints.medium} {
            font-size: 3.5rem;
          }
        }

        span {
          display: inline-block;
          font-size: 1.3rem;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 2rem;

          @media ${Theme.breakpoints.medium} {
            font-size: 2rem;
            line-height: 3.4rem;
          }
        }
      }
    }
  }
`
